@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Archivo:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700;900&display=swap');

$primaryColor: #2f80ed;
$white: #ffffff;
$primaryFont: 'Poppins', sans-serif;
$green: #27ae60;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .sendbird-incoming-og-message {
    .sendbird-incoming-og-message--inner {
      .sendbird-incoming-og-message--body {
        .sendbird-incoming-og-message__text-balloon {
          word-break: normal;
          text-align: left;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }

  .MuiDialog-paperWidthSm {
    max-width: 400px;
    width: 100%;
  }

  .MuiFormLabel-root.Mui-focused,
  .MuiButton-textPrimary,
  .MuiSvgIcon-colorPrimary,
  .MuiRadio-colorPrimary.Mui-checked {
    color: $primaryColor;
  }

  .MuiPickersToolbar-toolbar,
  .MuiPickersDay-daySelected {
    background-color: $primaryColor;
  }

  .picker-no-margin {
    .MuiFormControl-marginNormal {
      margin: 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 10px;
    color: $primaryColor;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $primaryColor;
  }

  .MuiAlert-filledSuccess {
    background-color: $green;
  }

  &.sendbird-theme--light {
    .sendbird-channel-header {
      .sendbird-channel-header__right-icon {
        display: none;
      }

      .sendbird-channel-header__title {
        .sendbird-channel-header__title--name,
        .sendbird-channel-header__title--user-id {
          overflow: hidden;
        }
      }
    }

    .time-text {
      font-size: 9px;
      color: #bbbbbb;
    }

    .sendbird-channel-preview {
      width: auto;
    }

    .sendbird-channel-preview__avatar {
      width: auto;
      height: auto;
      text-align: center;

      .user-role {
        border: 1px solid $primaryColor;
        font-size: 9px;
        color: $primaryColor;
        padding: 1px 3px;
        border-radius: 4px;
        position: absolute;
        right: 22px;
        top: 12px;
      }
    }

    .sendbird-channel-preview {
      .sendbird-channel-preview__content {
        .sendbird-channel-preview__content__lower {
          > span {
            height: 18px;
            overflow: hidden;
          }

          flex-direction: column;
          height: auto;
          margin-top: 2px;

          .sendbird-channel-preview__content__lower__unread-message-count {
            width: 21px;
            display: inline-block;
            margin: 0;
            position: absolute;
            right: 12px;
            bottom: 15px;
          }
        }

        .sendbird-channel-preview__content__upper__header__channel-name {
          max-width: 150px;
        }
      }
    }

    .sendbird-custom-avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;

      svg {
        height: 100%;
        width: 100%;
      }

      .group-icon {
        background: #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }

        svg {
          width: 80%;
          height: 80%;
        }
      }
    }

    .sendbird-channel-preview__action {
      display: none;
    }

    .sendbird-loader {
      color: $primaryColor;

      svg {
        path {
          fill: $primaryColor;
        }
      }
    }

    .sendbird-channel-list__body {
      max-height: calc(100vh - 231px);
      overflow-y: auto;
    }

    .sendbird-app__conversation-wrap {
      max-height: calc(100vh - 165px);
      min-height: calc(100vh - 165px);
      min-width: 440px;
    }

    .sendbird-conversation {
      min-height: calc(100vh - 210px);
      max-height: calc(100vh - 210px);

      .sendbird-notification {
        background: $primaryColor;
        display: none;
      }
    }

    .sendbird-app__channellist-wrap {
      .sendbird-channel-preview--active {
        border-left: solid 4px $primaryColor;
        background: rgba($color: $primaryColor, $alpha: 0.2);

        .sendbird-channel-preview__content__upper__header {
          .sendbird-label--color-onbackground-1 {
            color: $primaryColor;
          }
        }
      }
    }

    .sendbird-color--primary {
      color: $primaryColor;

      [class*='fill'] {
        fill: $primaryColor;
      }
    }

    .sendbird-user-message--outgoing {
      .sendbird-user-message__text-balloon {
        background: rgba($color: $primaryColor, $alpha: 0.2) !important;
        border-radius: 22px;
      }
    }

    .sendbird-badge {
      background: $primaryColor;
      display: flex;
      align-items: center;
      justify-content: center;

      .sendbird-badge__text {
        margin: 0;
      }
    }

    .sendbird-label--color-oncontent-1 {
      line-height: normal;
    }
  }

  .MuiBadge-colorPrimary {
    background-color: $primaryColor;
    right: -8px;
  }

  .MuiButton-root {
    padding: 8px 35px;
    font-size: 12px;
    box-shadow: none;
    font-family: $primaryFont;
    font-weight: 600;
    line-height: 16px;
    border-radius: 100px;
    letter-spacing: 0.3375px;
    text-transform: uppercase;

    &.MuiButton-outlinedPrimary {
      border-color: $primaryColor !important;
      color: $primaryColor !important;
    }

    &.MuiButton-containedPrimary {
      border-color: $primaryColor !important;
      background: $primaryColor !important;
      color: $white !important;
    }

    @media (max-width: 1024px) {
      padding: 8px 10px;
      font-size: 10px;
    }
  }

  .auto-height-input {
    .MuiInputBase-input {
      height: auto;
    }
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

a,
button {
  cursor: pointer;
}

a,
button,
input {
  outline: none !important;
}

.sendbird-channel-preview {
  .sendbird-channel-preview__content {
    .sendbird-channel-preview__content__upper {
      width: 100% !important;
    }
  }
}

.sendbird-channel-preview__content__upper__header {
  width: 100%;
}

.titleButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiIconButton-root {
    padding: 0px;
    position: absolute;
    right: 5px;
    background: none !important;
  }
}
#timeZoneDialog {
  .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: 550px;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
}

// material UI container
.MuiContainer-root {
  max-width: 1328px;

  @media (max-width: 1024px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.MuiPopover-paper {
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1) !important;
}

.opacity-0 {
  opacity: 0;
}
.padding-0 {
  padding: 0;
}

#delete-popover {
  .MuiPopover-paper {
    border-radius: 12px;
    border: 0px;
    box-shadow: none;
  }
}

.timeZoneSelectCustom div:first-child::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.timeZoneSelectCustom div:first-child::-webkit-scrollbar {
  width: 9px;
  background-color: transparent;
}

.timeZoneSelectCustom div:first-child::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.dialogContentScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.dialogContentScroll::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.dialogContentScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d4d4d4;
}

@media only screen and (max-width: 767px) {
  #intercom-container {
    display: none;
  }

  .intercom-launcher {
    display: none;
  }

  // hide chatbot widget
  .chatbotWidget {
    display: none;
  }
}

// custom select

#searchStudent {
  & .MuiMenu-paper {
    width: 334px;
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid #d4d4d4;
  }

  .searchScrollContent {
    flex-direction: column;
    align-items: flex-start;
    max-height: 240px;
    overflow: auto;

    & .active {
      background-color: #f4f4f4;
    }
  }

  .searchScrollContent::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: #eaeaea;
  }

  .searchScrollContent::-webkit-scrollbar {
    width: 9px;
    background-color: #eaeaea;
  }

  .searchScrollContent::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #a9a9a9;
  }

  & .MuiList-root {
    padding: 0;
  }

  & .sectionHeading {
    font-size: 12px;
    letter-spacing: -0.006em;
    color: #a9a9a9;
    padding: 8px 12px;

    &:hover {
      background-color: transparent;
    }
  }

  & .sectionItems {
    padding: 5px 12px;
    font-size: 16px;
    letter-spacing: -0.011em;
    color: #272929;
    white-space: break-spaces;
  }

  & .seachInputFilter {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    padding: 0px 12px 0px 30px;
    font-size: 14px;
    letter-spacing: -0.006em;
    color: #464646;
    background-image: url('./img/dropdown-search.svg');
    background-repeat: no-repeat;
    background-position: center left 8px;
  }

  & .inputWrapper {
    background: #f4f4f4;
    padding: 11px;
  }
}

.payoutScrollbar {
  height: 430px;
  overflow: auto !important;
  padding-right: 17px;
}

.payoutScrollbar::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: #eaeaea;
}

.payoutScrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

.payoutScrollbar::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #d4d4d4;
}

.sendbird-label.sendbird-channel-header__title--user-id.sendbird-label--body-2.sendbird-label--color-onbackground-2 {
  display: none;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.MuiPickersModal-dialogRoot {
  .MuiButton-root {
    padding: initial !important;
    font-size: initial !important;
    font-weight: initial !important;
    line-height: initial !important;
    border-radius: initial !important;
    letter-spacing: initial !important;
    text-transform: initial !important;
  }
}
.requiredText {
  color: red;
  font-size: 11px;
  font-family: 'Inter', sans-serif;

  .errorText.MuiTypography-caption {
    color: red;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
  }
}

.errorText {
  color: red;
  font-size: 10px !important;
  font-family: 'Poppins', sans-serif !important;
}

#datePickerModule,
#datePickerModuleEnd {
  .MuiList-padding {
    padding: 0px;
  }

  .datePickerPopover {
    width: 140px;
    height: 248px;
    border-radius: 4px;
    border: 1px solid #bfbfbf;
  }

  .scrollHours {
    height: 246px;
    overflow-y: auto;
    scrollbar-color: #f5f5f5 #e4e2e2;
    scrollbar-width: 7px;
  }

  .scrollHours::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #f5f5f5;
  }

  .scrollHours::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }

  .scrollHours::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #e4e2e2;
  }

  .MuiMenuItem-root {
    font-size: 13.5px;
    color: #272929;
    padding: 6px 10px;
    display: flex;
    justify-content: center;
    border-radius: 1px;
    border: 1px solid transparent;

    &:hover {
      background-color: #add1ff;
    }

    &:focus {
      font-weight: 600;
      background: #3f6bfe;
      color: #fff;
      border: 1px solid #101010;
    }
  }

  .selectedHours,
  .selectedMinutes,
  .selectedTImeCycle {
    font-weight: 600;
    background: #3f6bfe;
    color: #fff;
  }
}

.datePickerCustomModule {
  .MuiInputBase-input {
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    color: #272929;
    height: 40px;
    padding: 0px 15px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    letter-spacing: -0.006em;
    background-repeat: no-repeat;
    background-position: center right 16px;
    background-image: url('./img/select-arrow.svg');
    caret-color: transparent;

    &::placeholder {
      opacity: 1;
    }
  }

  .MuiTypography-caption {
    color: #d4d4d4;
  }
}

.advisorName {
  font-family: Inter, sans-serif !important;
}
.stuck {
  color: #fdb515 !important;
}
.stuck > div {
  color: #fdb515 !important;
}

.reviewReady {
  color: #992fed !important;
}
.reviewReady > div {
  color: #992fed !important;
}

.inProgress {
  color: #2f80ed !important;
}
.inProgress > div {
  color: #2f80ed !important;
}

.notStarted {
  color: #a9a9a9 !important;
}
.notStarted > div {
  color: #a9a9a9 !important;
}

// .complete,
.Completed {
  color: #45ce93 !important;
}
.Completed > div {
  color: #45ce93 !important;
}

.advisorName {
  font-family: 'Inter', sans-serif !important;
}

.availableTimeScroll {
  height: 280px;
  overflow: auto;
  margin-top: 15px;
}

.customizeScroll {
  height: 100%;
  overflow: auto;
}

.availableTimeScroll::-webkit-scrollbar-track,
.customizeScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.availableTimeScroll::-webkit-scrollbar,
.customizeScroll::-webkit-scrollbar {
  width: 9px;
  background-color: transparent;
}

.availableTimeScroll::-webkit-scrollbar-thumb,
.customizeScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.advisorSelectScroll .react-select__menu-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.advisorSelectScroll .react-select__menu-list::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.advisorSelectScroll .react-select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
}

.accountHeader {
  &.MuiTypography-h3 {
    font-weight: 700;
    font-size: 36px;
    color: #272929;
    letter-spacing: -0.022em;
    padding: 65px 0px 44px;
    font-family: 'Poppins', sans-serif;
  }
}

.editProfilePicture {
  label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.006em;
    color: #2f80ed;
    margin: 0px;
    line-height: 16px;
    border-bottom: 1px solid #2f80ed;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
  }

  input[type='file'] {
    display: none;
  }
}

.userProfileName {
  &.MuiTypography-h5 {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.014em;
    color: #272929;
    font-family: 'Inter', sans-serif;
  }
}

.accountContainer {
  padding-bottom: 100px;
}
.containerPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.acceptedToDropdown .MuiInputBase-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.acceptedToDropdown .MuiInputBase-root::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.acceptedToDropdown .MuiInputBase-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4d4d4;
}

.checkboxText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.disableText {
  opacity: 0.5 !important;
}

.tableCustomScroll::-webkit-scrollbar-track,
.activeIncentivesTable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.tableCustomScroll::-webkit-scrollbar,
.activeIncentivesTable::-webkit-scrollbar {
  height: 9px;
  background-color: transparent;
}

.tableCustomScroll::-webkit-scrollbar-thumb,
.activeIncentivesTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4d4d4;
}

.extracurricularsScrollGrid {
  max-width: 465px;

  h5.MuiTypography-h5 {
    font-size: 12px;
    font-weight: 600;
    color: #272929;
    margin: 0px;
    letter-spacing: -0.006em;
  }

  h6 {
    color: #7d7f7f;
    margin-bottom: 3px;
  }

  p.MuiTypography-body1 {
    color: #525454;
    margin: 10px 0px 0px;
  }

  h6,
  p.MuiTypography-body1 {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.006em;
  }

  h5.MuiTypography-h5,
  h6,
  p {
    font-family: 'Inter', sans-serif;
  }

  .activitiesDivider {
    margin: 26px 0px 22px;
    background-color: #d4d4d4;
  }
}

.dialogScroll {
  padding: 0px 42px;

  .react-select__menu-list {
    max-height: 200px;
  }

  @media only screen and (max-width: 600px) {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 35px 0px 0px;
  }
}

.undergraduateEducationDialog::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.undergraduateEducationDialog::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.meetingScroll {
  overflow: auto;
  max-height: 300px;
  padding-right: 20px;
}

.undergraduateEducationDialog::-webkit-scrollbar-thumb,
.meetingScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.editTimeDialogContent::-webkit-scrollbar-track,
.meetingScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.editTimeDialogContent::-webkit-scrollbar,
.meetingScroll::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.editTimeDialogContent::-webkit-scrollbar-thumb,
.meetingScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.incentiveBreakdownDialog::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.incentiveBreakdownDialog::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.incentiveBreakdownDialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a9a9a9;
}

.statusMenu {
  font-family: 'Poppins', sans-serif !important;
  background-color: $white;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: -0.006em !important;
  text-transform: uppercase !important;
}

.disableText {
  opacity: 0.5 !important;
}

.placeholderText div:first-child {
  color: #a9a9a9 !important;
}

.statusMenu > div {
  font-weight: bold !important;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: -0.006em !important;
  text-transform: uppercase !important;
}
